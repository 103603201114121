import React from "react"
import PropTypes from "prop-types"
// Components
import { Link, graphql } from "gatsby"
import { BlogList } from "../components/blog/blog-list"
import Layout from "../components/layout"
import Tags from "./blog-tags"
import { slugify } from "slug"
const BlogTags = props => {
  const { pageContext, data } = props
  const { tag } = pageContext
  const { edges, totalCount } = data.posts

  return <BlogList {...props} />
}
BlogTags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    posts: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}
export default BlogTags
export const pageQuery = graphql`
  query($tag: String, $skip: Int, $limit: Int) {
    posts: allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        fields: { tags: { in: [$tag] } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            tags
            category
          }
          frontmatter {
            featuredImage {
              childImageSharp {
                id
                fluid {
                  src
                }
              }
            }
            title
            tags
            category
          }
        }
      }
    }
    categories: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { title: { ne: "" } }
      }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              category
            }
            frontmatter {
              title
              featuredImage {
                childImageSharp {
                  id
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
      nodes {
        frontmatter {
          title
          category
        }
      }
    }
    tags: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { title: { ne: "" } }
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              tags
              slug
            }
            frontmatter {
              tags
            }
          }
        }
      }
    }
    latest_posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 90, maxHeight: 60) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
